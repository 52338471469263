import { take } from 'rxjs';
import { Injectable } from '@angular/core';

import { groupBy } from '@lib-core/helpers';
import { SalesOrderResource, SalesReturnResource } from '@lib-core/resources';
import { SalesReturnCampoDependente, SalesReturnProcessoModel } from '@lib-core/models';
import { SalesReturnFieldIdType, SalesReturnFieldNameType, SalesReturnType } from '@lib-core/enums';

@Injectable({
	providedIn: 'root',
})
export class SalesOrderService {
	constructor(private salesOrderResource: SalesOrderResource, private salesReturnResource: SalesReturnResource) {}

	queryOrders(customerCode: string, dateFrom: string, dateTo: string, representativeCode: string, prepostoCode: string) {
		return this.salesOrderResource.queryOrders(customerCode, dateFrom, dateTo, representativeCode, prepostoCode);
	}

	detailOrderItens(orderCode: string, isDownloadAllColors: boolean, isInvoiceDownload?: boolean) {
		return this.salesOrderResource.detailOrderItens(orderCode, isDownloadAllColors, isInvoiceDownload);
	}

	detailOrder(orderCode: string) {
		return this.salesOrderResource.detailOrder(orderCode);
	}

	listStatus() {
		return this.salesOrderResource.listOrderStatus();
	}

	getFluxoDetails(fluxo: number) {
		return this.salesReturnResource.getFluxoDetails(fluxo);
	}

	lisReturnOrders(cnpj: string, returnType: SalesReturnType) {
		return this.salesReturnResource.lisReturnOrders(cnpj, returnType);
	}

	getReturnOrderDetails(cnpj: string, id: number, returnType: SalesReturnType) {
		return this.salesReturnResource.getReturnOrderDetails(cnpj, id, returnType);
	}

	loadField(fieldId: number, customerCode: string) {
		return this.salesReturnResource.loadFieldData(fieldId, customerCode).pipe(take(1));
	}

	loadFieldWithDependent(fieldId: number, customerCode: string, dependentFieldList: SalesReturnCampoDependente[]) {
		return this.salesReturnResource.loadFieldDataWithDependent(fieldId, customerCode, dependentFieldList).pipe(take(1));
	}

	createSalesReturnCampoDependente(type: SalesReturnFieldIdType, value: string) {
		return {
			value: value,
			campoCoringaDependenteId: type,
			parametro: SalesReturnFieldNameType[type],
		} as SalesReturnCampoDependente;
	}

	saveProcess(process: SalesReturnProcessoModel) {
		return process.processo_id ? this.salesReturnResource.updateProcess(process) : this.salesReturnResource.createProcess(process);
	}

	parseDataToProcess(data: any, fluxo: number): SalesReturnProcessoModel {
		const processModel = new SalesReturnProcessoModel();

		if (!data || data?.length === 0) {
			return null;
		}

		try {
			data = data[0];
			processModel.fluxo_id = fluxo;
			processModel.processo_id = data?.id;
			processModel.etapa_id = data?.etapa?.id;
			processModel.motivo = data?.motivo?.id;

			const notes = data?.campos?.find((i: any) => i.campo.id == SalesReturnFieldIdType.Notes);
			const requester = data?.campos?.find((i: any) => i.campo.id == SalesReturnFieldIdType.Requester);

			processModel.capa = {
				[SalesReturnFieldIdType.Notes]: notes?.valor,
				[SalesReturnFieldIdType.Requester]: requester?.valor,
			};

			data?.anexos?.forEach((anexo: any) => {
				processModel.anexos.push({
					id: anexo.id,
					nome: anexo.nome,
					etapa: processModel.etapa_id,
					base64: anexo?.base64 ?? '',
					src: anexo.src,
					usuarioInclusao: anexo.usuarioInclusao,
				});
			});

			const listGroupedByUuid = groupBy(data?.listas, 'uuid');

			Object.keys(listGroupedByUuid).forEach((uuid: any) => {
				const valueList = listGroupedByUuid[uuid];

				const newList = {
					fluxo_id: processModel.fluxo_id,
					etapa_id: processModel.etapa_id,
					lista_id: valueList[0].lista.id,
					uuid: valueList[0].uuid,
					lista: [],
				};

				const list = [];

				valueList.forEach((item: any) => {
					const itemModel = {
						id: item.campo.id,
						tipo: item.campo.tipo,
						uuid: item.uuid,
						valor: item.valor,
						lista_uuid: item.uuid,
						canSelect: item.canSelect,
						usuarioInclusao: item.usuarioInclusao,
					};
					list.push(itemModel);
				});
				newList.lista.push(list);
				processModel.listas.push(newList);
			});
		} catch (e) {
			console.error(e);
		}

		return processModel;
	}

	sendMailWithSalesOrderCopy(salesOrderCode: string) {
		return this.salesOrderResource.sendMailWithSalesOrderCopy(salesOrderCode);
	}
}
