import { Injectable } from '@angular/core';
import { ImageDownloadType, MIMEType } from '@lib-core/enums';

import { download, downloadFile } from '@lib-core/helpers';
import { ImagesResource } from '@lib-core/resources/images.resource';

@Injectable({
	providedIn: 'root',
})
export class ImagesService {
	constructor(private imagesResource: ImagesResource) {}

	downloadImagesZip(array: any[], orderCode: string, isAllColorsFromOrder: boolean, isInvoiceDownload: boolean): Promise<void> {
		return this.imagesResource.downloadImagesZip(array, orderCode, isAllColorsFromOrder, isInvoiceDownload).then(response => {
			download(response, 'imagens', MIMEType.Zip);
		});
	}

	download(orderCode: string, type: ImageDownloadType): void {
		this.imagesResource.download(orderCode, type).subscribe(response => {
			downloadFile(response.data, MIMEType.Zip, this.getZipFileName(orderCode, type));
		});
	}

	getZipFileName(orderCode: string, type: ImageDownloadType): string {
		switch (type) {
			case ImageDownloadType.OnlyCover:
				return `arquivo_pedido_${orderCode}_capa.zip`;
			case ImageDownloadType.Complete:
				return `arquivo_${orderCode}_completo.zip`;
			case ImageDownloadType.FromInvoice:
				return `arquivo_pedido_${orderCode}_faturado.zip`;
			default:
				return '';
		}
	}
}
