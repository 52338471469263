<shared-page-title />
<div ngbAccordion class="mb-4">
	<div ngbAccordionItem [collapsed]="filterCollapsed">
		<h2 ngbAccordionHeader>
			<button ngbAccordionButton>Filtros</button>
		</h2>
		<div ngbAccordionCollapse>
			<div ngbAccordionBody>
				<ng-template>
					<form [formGroup]="filterForm" (ngSubmit)="onFilter()">
						<div class="row g-3">
							<div class="col-24">
								<label class="form-label" for="customers">Clientes</label>
								<ng-select formControlName="customers" [multiple]="true" [closeOnSelect]="false">
									<ng-option selected disabled value="">Selecione</ng-option>
									<ng-container *ngFor="let customer of customerList">
										<ng-option [value]="customer.code">{{ customer.code }} - {{ customer.name }}</ng-option>
									</ng-container>
								</ng-select>
							</div>
							<div class="col-24 col-md-12 col-xl-5">
								<label class="form-label" for="emittedFrom">Emitidos de</label>
								<input
									ngbDatepicker
									#dpFrom="ngbDatepicker"
									name="emittedFrom"
									class="form-control"
									placeholder="dd/mm/aaaa"
									formControlName="emittedFrom"
									(click)="dpFrom.toggle()"
									[ngClass]="{ 'is-invalid': submitted && f.emittedFrom.errors }" />
								<div *ngIf="submitted && f.emittedFrom.errors" class="invalid-feedback">
									<div *ngIf="f.emittedFrom.errors.required">Informe uma data válida</div>
								</div>
							</div>
							<div class="col-24 col-md-12 col-xl-5">
								<label class="form-label" for="emittedTo">Emitidos até</label>
								<input
									ngbDatepicker
									#dpTo="ngbDatepicker"
									name="emittedTo"
									class="form-control"
									placeholder="dd/mm/aaaa"
									formControlName="emittedTo"
									(click)="dpTo.toggle()"
									[ngClass]="{ 'is-invalid': submitted && f.emittedTo.errors }" />
								<div *ngIf="submitted && f.emittedTo.errors" class="invalid-feedback">
									<div *ngIf="f.emittedTo.errors.required">Informe uma data válida</div>
								</div>
							</div>
							<div class="col-24 d-flex justify-content-between">
								<div>
									<button type="submit" class="btn btn-primary ms-auto">Filtrar Notas</button>
									<button type="reset" class="btn btn-secondary ms-2" (click)="onReset()">Limpar</button>
								</div>
								<button type="button" class="btn btn-outline-secondary" (click)="onRestore()">Restaurar filtros</button>
							</div>
						</div>
					</form>
				</ng-template>
			</div>
		</div>
	</div>
</div>

<div>
	<table *ngIf="invoiceList" datatable [dtOptions]="dtOptions" aria-describedby="tabela-notas-fiscais" class="table table-striped w-100">
		<thead>
			<tr>
				<th class="text-center" scope="col">Número</th>
				<th class="text-center" scope="col">CNPJ</th>
				<th class="text-center" scope="col">Cliente</th>
				<th class="text-center" scope="col">Ordem</th>
				<th class="text-center" scope="col">Vendedor</th>
				<th class="text-center" scope="col">Faturamento</th>
				<th class="text-center" scope="col">Valor</th>
				<th class="text-center" scope="col">Transportadora</th>
				<th class="text-center" scope="col">Embarque</th>
				<th class="text-center" scope="col">Ações</th>
			</tr>
		</thead>
		<tbody *ngIf="invoiceList && invoiceList.length > 0" class="table-group-divider">
			@for (invoice of invoiceList; track invoice.notaFiscal) {
			<tr class="align-middle text-center">
				<td class="text-center">{{ invoice.notaFiscal }}</td>
				<td class="text-center">{{ invoice.cnpj | cpfCnpj }}</td>
				<td class="text-center">{{ invoice.nomeCliente }}</td>
				<td class="text-center">{{ invoice.ordem }}</td>
				<td class="text-center">{{ invoice.vendedor }}</td>
				<td class="text-center">{{ invoice.faturamento | shortDate }}</td>
				<td class="text-center">{{ invoice.valor | brl }}</td>
				<td class="text-center">{{ invoice.nomeTransportadora }}</td>
				<td class="text-center">{{ invoice.embarque | shortDate }}</td>
				<td class="text-center">
					<div ngbDropdown class="position-static">
						<button class="btn btn-outline-primary ms-2" id="dropdownBasic1" ngbDropdownToggle>Ações</button>
						<div ngbDropdownMenu aria-labelledby="dropdownBasic1">
							<button ngbDropdownItem (click)="onDownloadXml(invoice)">
								<i class="bi bi-file-earmark-code me-1"></i>
								<span>Baixar XML</span>
							</button>
							<button ngbDropdownItem (click)="onDownloadDanfe(invoice)">
								<i class="bi bi-file-earmark-text me-1"></i>
								<span>Baixar DANFE</span>
							</button>
							<button ngbDropdownItem (click)="onDownloadCSV(invoice)">
								<i class="bi bi-file-earmark-spreadsheet me-1"></i>
								<span>Baixar Planilha</span>
							</button>
							<button ngbDropdownItem (click)="onDownloadImages(invoice.ordem)">
								<i class="bi bi-file-earmark-image me-1"></i>
								<span>Baixar imagens do Pedido</span>
							</button>
						</div>
					</div>
				</td>
			</tr>
			}
		</tbody>
	</table>
</div>
