import { Injectable } from '@angular/core';

import { MIMEType } from '@lib-core/enums';
import { downloadFile } from '@lib-core/helpers';
import { InvoiceResource } from '@lib-core/resources';
import { InvoiceRequestDto } from '@lib-core/dtos';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class InvoiceService {
	constructor(private invoiceResource: InvoiceResource) {}

	queryInvoices(filter: InvoiceRequestDto) {
		return this.invoiceResource.queryInvoices(filter);
	}

	downloadXml(nfeKey: string) {
		this.invoiceResource.downloadXml(nfeKey).subscribe(response => {
			downloadFile(response.result, MIMEType.Xml, nfeKey);
		});
	}

	downloadXmlForCSV(nfeKey: string): Observable<any> {
		return this.invoiceResource.downloadXmlForCSV(nfeKey);
	}

	downloadCSV(nfeKey: string) {
		this.invoiceResource.downloadXmlForCSV(nfeKey).subscribe(response => {
			downloadFile(response.result, MIMEType.Xml, nfeKey);
		});
	}

	downloadDanfe(nfeKey: string) {
		this.invoiceResource.downloadDanfe(nfeKey).subscribe(response => {
			downloadFile(response.result, MIMEType.Pdf, nfeKey);
		});
	}
}
