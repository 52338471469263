import { fromBase64 } from '@lib-core/helpers/base64.helper';
import { isStringNullOrEmpty } from '@lib-core/helpers/string.helper';
import { ExtensionByMimeTypeMap, MIMEType } from '@lib-core/enums';

function downloadByteArray(base64data: ArrayBuffer, type: MIMEType, fileName: string = null): void {
	const blob = new Blob([base64data], { type });
	const url = window.URL.createObjectURL(blob);
	const a = document.createElement('a');

	a.href = url;
	a.target = '_new';
	a.download = createFileName(fileName, type);
	a.click();
}

function downloadFile(base64data: string, type: MIMEType, fileName: string = null): void {
	if (isStringNullOrEmpty(base64data)) return;

	const blobData = stringToBlob(base64data);
	const blob = new Blob([blobData], { type });
	const url = window.URL.createObjectURL(blob);
	const a = document.createElement('a');

	a.href = url;
	a.target = '_new';
	a.download = createFileName(fileName, type);
	a.click();
}

function stringToBlob(base64Data: string): Uint8Array {
	const data = fromBase64(base64Data);
	const buffer = new ArrayBuffer(data.length);
	const array = new Uint8Array(buffer);

	for (let i = 0; i < data.length; i++) {
		array[i] = data.charCodeAt(i);
	}

	return array;
}

function download(value: string, name = '', type: MIMEType): void {
	const a = document.createElement('a');
	a.href = value;
	a.target = '_blank';
	a.download = createFileName(name, type);
	a.click();
}

function createFileName(fileName: string, type: MIMEType, unique = true): string {
	const timestamp = new Date().getTime();

	if (!fileName) {
		return `${timestamp}.${ExtensionByMimeTypeMap[type]}`;
	}

	if (unique) {
		return `${fileName}_${timestamp}.${ExtensionByMimeTypeMap[type]}`;
	}

	return `${fileName}.${ExtensionByMimeTypeMap[type]}`;
}

function downloadCSV(blob, fileName) {
	const url = window.URL.createObjectURL(blob);
	const a = document.createElement('a');
	a.href = url;
	a.target = '_blank';
	a.download = `${fileName}.csv`;
	a.click();
	URL.revokeObjectURL(url);
}

function convertXmlToCSV(xmlString) {
	const xmlDoc = new DOMParser().parseFromString(xmlString, 'application/xml');
	const items = Array.from(xmlDoc.getElementsByTagName('det'));

	const data = [['Material', 'EAN', 'Nome', 'Cor', 'Tamanho', 'Quantidade', 'Preço', 'Total']];

	items.forEach(item => {
		const parsedItem = parseItem(item);
		data.push(parsedItem);
	});

	return createCsvBlob(data);
}

function parseItem(item) {
	const nItem = item.getAttribute('nItem');
	const product = item.getElementsByTagName('prod')[0];
	const productEAN = getTextContent(product, 'cEAN');
	const productName = getTextContent(product, 'xProd');
	const productQuantity = parseInt(getTextContent(product, 'qCom'));
	const productPrice = parseFloat(getTextContent(product, 'vUnCom')).toFixed(2);
	const total = (productQuantity * parseFloat(productPrice)).toFixed(2);

	const { name, color, size } = extractProductDetails(productName);

	return [nItem, productEAN, name, formatColor(color), size, productQuantity, productPrice, total];
}

function getTextContent(parent, tagName) {
	return parent.getElementsByTagName(tagName)[0]?.textContent || '';
}

function extractProductDetails(productName) {
	const match = productName.match(/(.*) Cor (.*) Tam (.*)/);
	return match ? { name: match[1], color: match[2], size: match[3] } : { name: productName, color: '', size: '' };
}

function formatColor(color) {
	return color.padStart(5, '0');
}

function createCsvBlob(data) {
	const csvContent = data.map(row => row.join(',')).join('\n');
	return new Blob([csvContent], { type: 'text/csv' });
}

export { download, downloadFile, downloadByteArray, downloadCSV, convertXmlToCSV };
