<shared-page-title />
<div ngbAccordion class="mb-4">
	<div ngbAccordionItem [collapsed]="false">
		<h2 ngbAccordionHeader>
			<button ngbAccordionButton>Filtros</button>
		</h2>
		<div ngbAccordionCollapse>
			<div ngbAccordionBody>
				<ng-template>
					<form class="needs-validation" [formGroup]="filterForm" (ngSubmit)="onFilter()" novalidate="">
						<div class="row g-3 align-items-end">
							<div class="col-24 col-lg-12 col-xl-4">
								<label class="form-label" for="dateFrom">Emitidos de</label>
								<input
									id="dateFrom"
									type="email"
									ngbDatepicker
									#dpFrom="ngbDatepicker"
									(click)="dpFrom.toggle()"
									class="form-control"
									formControlName="dateFrom"
									[ngClass]="{ 'is-invalid': submitted && f.dateFrom.errors }" />
								<div class="invalid-feedback">Informe uma data válida</div>
							</div>
							<div class="col-24 col-lg-12 col-xl-4">
								<label class="form-label" for="dateTo">Emitidos até</label>
								<input
									id="dateTo"
									type="email"
									ngbDatepicker
									#dpTo="ngbDatepicker"
									(click)="dpTo.toggle()"
									class="form-control"
									formControlName="dateTo"
									[ngClass]="{ 'is-invalid': submitted && f.dateTo.errors }" />
								<div class="invalid-feedback">Informe uma data válida</div>
							</div>
							<div class="col-24">
								<button type="submit" class="btn btn-primary ms-auto">Filtrar Pedidos</button>
							</div>
						</div>
					</form>
				</ng-template>
			</div>
		</div>
	</div>
</div>

<table *ngIf="salesOrderList" datatable [dtOptions]="dtOptions" aria-describedby="tabela-devoluções" class="table table-striped w-100">
	<thead>
		<tr>
			<th class="text-center" scope="col">Número do Pedido</th>
			<th class="text-center" scope="col">Código do Cliente</th>
			<th class="text-center" scope="col">Nome do Cliente</th>
			<th class="text-center" scope="col">Nome do Representante</th>
			<th class="text-center" scope="col">Nome do Vendedor</th>
			<th class="text-center" scope="col">Status</th>
			<th class="text-center" scope="col">Quantidade</th>
			<th class="text-center" scope="col">Valor Total</th>
			<th class="text-center" scope="col">Condição de Venda</th>
			<th class="text-center" scope="col">Data de Entrada</th>
			<th class="text-center" scope="col">Prev. Faturamento</th>
			<th class="text-center" scope="col">Ações</th>
		</tr>
	</thead>
	<tbody *ngIf="salesOrderList.length > 0" class="table-group-divider">
		@for (order of salesOrderList; track order.cD_DOCUMENTO) {
		<tr class="align-middle">
			<td class="text-center">{{ order.cD_DOCUMENTO }}</td>
			<td class="text-center">{{ order.cD_CLIENTE }}</td>
			<td class="text-center">{{ order.additionalProperties.NM_CLIENTE }}</td>
			<td class="text-center">{{ order.additionalProperties.NM_REPRESENTANTE }}</td>
			<td class="text-center">{{ order.additionalProperties.NM_VENDEDOR }}</td>
			<td class="text-center">{{ getStatusDescription(order.cD_STATUS) }}</td>
			<td class="text-center">{{ order.qtD_ORDEM_VENDA }}</td>
			<td class="text-center">{{ order.vlR_ORDEM_VENDA | brl }}</td>
			<td class="text-center">{{ order.cD_CONDICAO_PGTO }}</td>
			<td class="text-center">{{ order.dT_ENTRADA | shortDate }}</td>
			<td class="text-center">{{ order.dT_FATURAMENTO | shortDate }}</td>
			<td class="text-center">
				<div ngbDropdown class="position-static">
					<button type="button" class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>Ações</button>
					<div ngbDropdownMenu aria-labelledby="dropdownBasic1">
						<button ngbDropdownItem (click)="showOrderDetailsModal(order)"><i class="bi bi-list me-1"></i> Detalhes</button>
					</div>
				</div>
			</td>
		</tr>
		}
	</tbody>
</table>

<ng-template #orderDetails let-modal>
	<div class="modal-header d-flex justify-content-between align-items-center">
		<h4 class="modal-title mb-0" id="modal-basic-title">Detalhes do pedido</h4>
		<button type="button" class="btn btn-outline-secondary" aria-label="Close" (click)="clearOrderInformation()">
			<i class="bi bi-x-lg"></i>
		</button>
	</div>

	<div class="p-3 d-flex flex-lg-row flex-column justify-content-lg-end gap-1">
		<div class="dropdown">
			<button class="btn btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
				Ações do pedido
			</button>
			<ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
				<li *ngIf="showReorderOption">
					<button class="dropdown-item" type="button" (click)="sendOrderToCart()" [disabled]="isSendToCartDisabled">
						<i class="bi bi-arrow-repeat me-1"></i>
						<span>Refazer pedido</span>
					</button>
				</li>
				<li *ngIf="showReorderOption"><hr class="dropdown-divider" /></li>
				<li>
					<button class="dropdown-item" type="button" (click)="downloadCoverImages(salesOrder)"><i class="bi bi-image me-1"></i> Baixar imagens de capa</button>
				</li>
				<li>
					<button class="dropdown-item" type="button" (click)="downloadCompleteImages(salesOrder)"><i class="bi bi-images me-1"></i> Baixar imagens completo</button>
				</li>
				<li><hr class="dropdown-divider" /></li>
				<li>
					<button class="dropdown-item" type="button" (click)="printModalContent()"><i class="bi bi-printer me-1"></i> Imprimir pedido</button>
				</li>
			</ul>
		</div>
	</div>

	<div class="modal-body">
		<div class="d-flex mb-4 row justify-content-between text-center">
			<h2>PEDIDO #{{ salesOrder.cD_DOCUMENTO }}</h2>
			<p>REALIZADO: {{ salesOrder.dT_ENTRADA | shortDate }}</p>
			<p *ngIf="salesOrder.dT_FATURAMENTO">
				<strong>PREVISÃO DE FATURAMENTO: {{ salesOrder.dT_FATURAMENTO | shortDate }}</strong>
			</p>
		</div>
		<div>
			<div class="d-lg-flex justify-content-between">
				<div *ngIf="customer" class="mb-4 flex-grow-1 me-lg-4">
					<div ngbAccordion>
						<div ngbAccordionItem [collapsed]="false">
							<h2 ngbAccordionHeader>
								<button ngbAccordionButton>Informações do Cliente</button>
							</h2>
							<div ngbAccordionCollapse>
								<div ngbAccordionBody>
									<ng-template>
										<div core-label-value label="Código" [value]="customer.cD_CLIENTE" [colon]="true" [singleLine]="true"></div>
										<div core-label-value label="Nome" [value]="customer.nM_CLIENTE" [colon]="true" [singleLine]="true"></div>
										<div core-label-value label="E-mail" [value]="customer.email" [colon]="true" [singleLine]="true"></div>
										<div core-label-value label="CNPJ" [value]="customer.cnpj" [colon]="true" [singleLine]="true"></div>
										<div core-label-value label="Celular" [value]="customer.telefone" [colon]="true" [singleLine]="true"></div>
										<div core-label-value label="Telefone" [value]="customer.celular" [colon]="true" [singleLine]="true"></div>
									</ng-template>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div *ngIf="customer" class="mb-4">
					<div ngbAccordion>
						<div ngbAccordionItem [collapsed]="false">
							<h2 ngbAccordionHeader>
								<button ngbAccordionButton>Endereço do Cliente</button>
							</h2>
							<div ngbAccordionCollapse>
								<div ngbAccordionBody>
									<ng-template>
										<div core-label-value label="Rua" [value]="customer.logradouro" [colon]="true" [singleLine]="true"></div>
										<div core-label-value label="Número" [value]="customer.numero" [colon]="true" [singleLine]="true"></div>
										<div core-label-value label="Bairro" [value]="customer.bairro" [colon]="true" [singleLine]="true"></div>
										<div core-label-value label="Cidade" [value]="customer.municipio" [colon]="true" [singleLine]="true"></div>
										<div core-label-value label="Estado" [value]="customer.uf" [colon]="true" [singleLine]="true"></div>
										<div core-label-value label="CEP" [value]="customer.cep" [colon]="true" [singleLine]="true"></div>
									</ng-template>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-24">
				<div class="mb-4" ngbAccordion>
					<div ngbAccordionItem [collapsed]="true">
						<h2 ngbAccordionHeader>
							<button ngbAccordionButton>Informações do Pedido</button>
						</h2>
						<div ngbAccordionCollapse>
							<div ngbAccordionBody>
								<ng-template>
									<div core-label-value label="Forma de pagamento" [value]="salesOrder.cD_CONDICAO_PGTO" [colon]="true" [singleLine]="true"></div>
									<div core-label-value label="Modalidade" [value]="loadModality(salesOrder.cD_MODALIDADE)" [colon]="true" [singleLine]="true"></div>
									<div core-label-value label="Equipe de Venda" [value]="loadSalesTeam(salesOrder.cD_EQUIPE)" [colon]="true" [singleLine]="true"></div>
									<div core-label-value label="Prazo de entrega" [value]="salesOrder.cD_PRAZO_EXTRA" [colon]="true" [singleLine]="true"></div>
									<div core-label-value label="Descontos" [value]="salesOrder.descontos" [colon]="true" [singleLine]="true"></div>
									<div core-label-value label="Código do Representante" [value]="salesOrder.cD_REPRESENTANTE" [colon]="true" [singleLine]="true"></div>
									<div
										core-label-value
										label="Nome do Representante"
										[value]="salesOrder.additionalProperties.NM_REPRESENTANTE"
										[colon]="true"
										[singleLine]="true"></div>
									<div core-label-value label="Código do Vendedor" [value]="salesOrder.cD_VENDEDOR" [colon]="true" [singleLine]="true"></div>
									<div core-label-value label="Nome do Vendedor" [value]="salesOrder.additionalProperties.NM_VENDEDOR" [colon]="true" [singleLine]="true"></div>
								</ng-template>
							</div>
						</div>
					</div>
				</div>

				<div class="col-24 mt-2 mb-4">
					<div ngbAccordion>
						<div ngbAccordionItem [collapsed]="isAccordionClosed">
							<h2 ngbAccordionHeader>
								<button ngbAccordionButton (click)="toggleAccordion()">Produtos do Pedido</button>
							</h2>
							<div ngbAccordionCollapse>
								<div ngbAccordionBody class="p-2">
									<ng-container *ngFor="let item of orderProductsItems; let i = index">
										<div *ngIf="item?.cD_MATERIAL" [ngClass]="{ 'mt-4': i > 0 }">
											<core-group-box [title]="item?.cD_MATERIAL">
												<div class="row p-2">
													<div class="">
														<ng-template #header>
															<label class="form-check-label text-truncate" [for]="item.productCode">
																{{ item.additionalProperties.NM_MATERIAL }} - {{ item.cD_MATERIAL }}
															</label>
														</ng-template>
														<ng-template #body>
															<div class="row">
																<div class="col-lg-4 mb-4 mb-lg-0 col-24 d-flex justify-content-center align-items-center">
																	<app-product-image [height]="150" [url]="getImageUrlFromProduct(item.cD_MATERIAL)"></app-product-image>
																</div>

																<div class="col-lg-20 col-24 overflow-auto">
																	<div class="container">
																		<div class="row">
																			<div class="col-24">
																				<div *ngIf="item?.cD_MATERIAL">
																					<table class="table table-striped table-bordered">
																						<thead>
																							<tr>
																								<th scope="col">COR/TAMANHO</th>
																								<ng-container *ngFor="let size of uniqueSizes(item.cD_MATERIAL)">
																									<th scope="col" class="text-center">{{ size }}</th>
																								</ng-container>
																							</tr>
																						</thead>
																						<tbody>
																							<ng-container *ngFor="let color of uniqueColors(item.cD_MATERIAL)">
																								<tr>
																									<td>{{ color | uppercase }}</td>
																									<ng-container *ngFor="let size of uniqueSizes(item.cD_MATERIAL)">
																										<td class="text-center">
																											{{ getQuantityByColorAndSize(item.cD_MATERIAL, color, size) || 0 }}
																										</td>
																									</ng-container>
																								</tr>
																							</ng-container>
																						</tbody>
																						<tfoot>
																							<tr>
																								<td>Total</td>
																								<ng-container *ngFor="let size of uniqueSizes(item.cD_MATERIAL)">
																									<td class="text-center">
																										{{ getTotalBySize(item.cD_MATERIAL, size) }}
																									</td>
																								</ng-container>
																							</tr>
																						</tfoot>
																					</table>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>

															<div class="col-24">
																<h5 class="text-end pe-3 pb-1">Valor: {{ item.vlR_ITEM | brl }}</h5>
															</div>
														</ng-template>
													</div>
												</div>
											</core-group-box>
										</div>
									</ng-container>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="col-24">
					<div class="d-lg-flex flex-lg-row justify-content-lg-between p-2">
						<p>Total de Itens: {{ numberOfItems }}</p>
						<p>Quantidade de Peças: {{ salesOrder.qtD_ORDEM_VENDA }}</p>
						<p>Valor Total do Pedido: {{ salesOrder.vlR_ORDEM_VENDA | brl }}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer d-flex justify-content-end">
		<button type="button" class="btn btn-outline-secondary" (click)="clearOrderInformation()">fechar</button>
	</div>
</ng-template>

<ng-template #modalitySwitchModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Atenção!</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
	</div>
	<div class="modal-body">
		<form>
			<div class="mb-3">
				<p>O pedido possui uma modalidade diferente da atual selecionada. Deseja alterar a modalidade atual e prosseguir?</p>
			</div>
		</form>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Cancelar</button>
		<button type="button" class="btn btn-primary" (click)="modal.close('confirm')">Confirmar</button>
	</div>
</ng-template>

<ng-template #modalityNullModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Atenção!</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
	</div>
	<div class="modal-body">
		<p>O pedido não possui código de modalidade. Conferir situação de pedido com o suporte.</p>
	</div>
</ng-template>

<ng-template #clearCartModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Atenção!</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
	</div>
	<div class="modal-body">
		<form>
			<div class="mb-3">
				<p>Sua sacola de compras já possui items. Deseja limpar a sacola e continuar refazendo o pedido?</p>
			</div>
		</form>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Cancelar</button>
		<button type="button" class="btn btn-primary" (click)="modal.close('confirm')">Confirmar</button>
	</div>
</ng-template>
