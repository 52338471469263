export enum MIMEType {
	Json = 'application/json',
	Html = 'text/html',
	Text = 'text/plain',
	Xml = 'application/xml',
	Pdf = 'application/pdf',
	Zip = 'application/zip',
	Csv = 'application/csv'
}

export const ExtensionByMimeTypeMap = Object.freeze({
	[MIMEType.Json]: 'json',
	[MIMEType.Html]: 'html',
	[MIMEType.Text]: 'txt',
	[MIMEType.Xml]: 'xml',
	[MIMEType.Pdf]: 'pdf',
	[MIMEType.Zip]: 'zip',
	[MIMEType.Csv]: 'csv'
});
