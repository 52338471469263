import { RouterLink } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { NgbAccordionModule, NgbNavModule, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnDestroy, OnInit, effect } from '@angular/core';
import { FormGroup, Validators, FormsModule, FormBuilder, ReactiveFormsModule } from '@angular/forms';

import { GALLERY_ROUTE_NAMES } from '@proj-b2b/app/modules/gallery/gallery.constants';

import { scrollToTop } from '@lib-core/helpers';
import { PageTitleComponent } from '@lib-shared/components';
import { CartEmptyComponent } from '@proj-b2b/app/modules/cart/components/cart-empty/cart-empty.component';
import { ProductImageComponent } from '@proj-b2b/app/components/product-image/product-image.component';
import { CartProductItemTableComponent } from '@proj-b2b/app/modules/cart/components/cart-product-item-table/cart-product-item-table.component';
import { CartOrderedSuccessfullyComponent } from '@proj-b2b/app/modules/cart/components/cart-ordered-successfully/cart-ordered-successfully.component';
import { CartItemStatusType, CartStatusType } from '@lib-core/enums';
import { GroupBoxComponent, LabelValueComponent } from '@lib-core/components';
import { CapitalizePipe, CurrencyBrlPipe, PercentPipe } from '@lib-core/pipes';
import { CartCountStore, CartStore, GalleryRequestStore } from '@lib-core/stores';
import { AppliedDiscountsModel, CartItemModel, CartModel } from '@lib-core/models';
import { GalleryRequestDto, CartItemRequestDto, PaymentConditionResponseDto } from '@lib-core/dtos';
import { CartService, ToastService, RouterService, PaymentConditionService, SalesOrderService } from '@lib-core/services';

@Component({
	selector: 'app-cart-details',
	standalone: true,
	imports: [
		RouterLink,
		PercentPipe,
		FormsModule,
		NgbNavModule,
		CommonModule,
		CapitalizePipe,
		CurrencyBrlPipe,
		GroupBoxComponent,
		NgbAccordionModule,
		PageTitleComponent,
		CartEmptyComponent,
		LabelValueComponent,
		ReactiveFormsModule,
		ProductImageComponent,
		CartProductItemTableComponent,
		CartOrderedSuccessfullyComponent,
		NgbTooltip,
	],
	templateUrl: './cart-details.component.html',
})
export class CartDetailsComponent implements OnInit, OnDestroy {
	private subscriptionList: Subscription[] = [];
	submitted = false;
	cartReadyToSend = false;
	galleryRoutes = GALLERY_ROUTE_NAMES;

	cart: CartModel = null;
	cartForm!: FormGroup;
	request: GalleryRequestDto = null;
	paymentCondition: PaymentConditionResponseDto = null;

	constructor(
		private fb: FormBuilder,
		private cartStore: CartStore,
		private cartCountStore: CartCountStore,
		private cartService: CartService,
		private toastService: ToastService,
		private routerService: RouterService,
		private galleryRequestStore: GalleryRequestStore,
		private paymentConditionService: PaymentConditionService,
		private salesOrderService: SalesOrderService,
	) {
		this.request = this.galleryRequestStore.get();

		effect(() => {
			this.cart = this.cartStore.get();
			this.getPaymentConditionList();
		});
	}

	ngOnInit() {
		this.registerForm();
	}

	ngOnDestroy() {
		this.subscriptionList.forEach(subscription => subscription.unsubscribe());
	}

	get f() {
		return this.cartForm.controls;
	}

	get hasProducts() {
		return this.cart?.cartItemList && this.cart?.cartItemList.length > 0;
	}

	get orderSent() {
		return this.cart?.status === CartStatusType.Sent;
	}

	get error() {
		return this.cart?.integrationErrors?.length > 0 ? this.cart.integrationErrors[0].mensagem : this.cart.message;
	}

	get isCartValid() {
		return this.cart.cartItemList.every(item => item.status != CartItemStatusType.Error);
	}

	get isCartValidAndReadyToSend() {
		return this.isCartValid && this.cartReadyToSend;
	}

	get isAllowedAdditionalDaysForPayment() {
		return (this.paymentCondition?.allowedPaymentConditions?.find(x => x.code === this.cart.paymentConditionCode)?.allowAdditionalDaysForPayment === true && this.paymentCondition.maxExtra > 0);
	}

	registerForm() {
		this.cartForm = this.fb.group({
			paymentConditionCode: ['', [Validators.required]],
			numberOfAdditionalDaysForPayment: [null],
		});
	}

	getDiscountTotal() {
		return this.cart.grossValue - this.cart.netValue;
	}

	get TooltipMessage(): string {
		if (this.isCartValidAndReadyToSend) {
			return '';
		}
		return 'Por favor, selecione uma condição de pagamento para finalizar o pedido.';
	}

	getDiscountDescription(discount: AppliedDiscountsModel) {
		return `${discount.description} (${discount.code})`;
	}

	getTitle(cartItem: CartItemModel): string {
		if (cartItem.productDetails) {
			return `${cartItem.productDetails?.description} (${cartItem.productDetails?.code})`;
		}
		return `PRODUTO INDISPONÍVEL (${cartItem.productCode})`;
	}

	showItemMessage(cartItem: CartItemModel): boolean {
		return cartItem.productDetails == null || (cartItem.status === CartItemStatusType.Error && !!cartItem.message);
	}

	getImageUrl(cartItem: CartItemModel): string {
		return cartItem.productDetails?.imagesUrl?.find(image => image.url !== null)?.url ?? '';
	}

	isItemValid(cartItem: CartItemModel): boolean {
		return cartItem.productDetails != null;
	}

	getTotalItems() {
		return this.cart.cartItemList.filter(item => item.status != CartItemStatusType.Error).reduce((acc, item) => acc + item.quantity, 0);
	}

	onEdit(item: CartItemModel) {
		if (this.isItemValid(item)) {
			this.routerService.goToProduct(item.productCode);
		}
	}

	onRemove(item: CartItemModel) {
		const request = new CartItemRequestDto();
		request.productCode = item.productCode;

		this.subscriptionList.push(
			this.cartService.removeItems([request]).subscribe(() => {
				this.toastService.success(`O produto ${item.productCode} foi removido da sacola`);
			}),
		);
	}
	getPaymentConditionList() {
		if (this.cart == null || this.paymentCondition != null) {
			return;
		}

		const subscription = this.paymentConditionService.queryPaymentConditionsByCustomer(this.cart.customerCode, this.cart.modalityCode).subscribe(response => {
			this.paymentCondition = response;
			this.patchCommercialRules();
		});

		this.subscriptionList.push(subscription);
	}

	patchCommercialRules() {
		if (this.cart.paymentConditionCode != null) {
			this.cartForm.patchValue({
				paymentConditionCode: this.cart.paymentConditionCode,
				numberOfAdditionalDaysForPayment: this.cart.numberOfAdditionalDaysForPayment > 0 ? this.cart.numberOfAdditionalDaysForPayment : null,
			});
			this.updateCartFormValidators();
			this.processCart();
		}
	}

	updateCartFormValidators() {
		this.cartForm.get('numberOfAdditionalDaysForPayment').setValidators(Validators.max(this.paymentCondition.maxExtra));
	}

	clearCommercialRules() {
		this.cartForm.patchValue({
			paymentConditionCode: null,
			numberOfAdditionalDaysForPayment: null,
		});
	}

	onChangePaymentCondition() {
		this.cartForm.patchValue({
			numberOfAdditionalDaysForPayment: null,
		});
		this.processCart();
	}

	processCart() {
		this.submitted = true;

		this.updateCartFormValidators();

		if (this.cartForm.invalid) {
			return;
		}

		this.submitted = false;
		this.cartReadyToSend = false;

		const subscription = this.cartService
			.processCart({
				cartId: this.cart.id,
				paymentConditionCode: this.cartForm.value.paymentConditionCode,
				additionalDaysForPayment: this.cartForm.value.numberOfAdditionalDaysForPayment ?? 0,
			})
			.subscribe(response => {
				if (response.success) {
					this.cartReadyToSend = true;
					this.cartStore.set(response.data);
					return;
				}
				this.toastService.danger(response.message);
			});
		this.subscriptionList.push(subscription);
	}

	sendToErp(cart: CartModel) {
		this.submitted = true;

		if (this.cartForm.invalid || !this.isCartValid || !this.isCartValidAndReadyToSend) {
			return;
		}

		this.submitted = false;
		this.cartService.finishCart(cart.id, cart.paymentConditionCode).subscribe(response => {
			if (response.message && response.message.length > 0) {
				this.cart.message = response.message;
			}
			if (response.success) {
				this.cartStore.set(response.data);
				this.cartCountStore.clear();

				if (response.data.erpSalesOrderCode) {
					this.salesOrderService.sendMailWithSalesOrderCopy(response.data.erpSalesOrderCode);
				}
				scrollToTop();
				return;
			}
			this.toastService.danger(response.message);
		});
	}
}
